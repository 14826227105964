import React from 'react'
import { css, cx } from '../../../styled-system/css'
import { packages } from '@dup/products'
import { navigate } from 'gatsby'
import { Image, getPath } from './Image'
import { Link } from 'gatsby'

export const Albums = () => {
  function handleClickNav(dir: string) {
    if (dir) {
      const goTo = '/photographie/' + dir
      navigate(goTo)
    }
  }

  const getPackage = (id: string) => {
    const pkg = packages.find((_package) => _package.id === id)

    const imageName =
      typeof window !== 'undefined' && window.innerWidth > 768
        ? `losange.webp`
        : `${pkg?.images.at(0)?.name}`

    if (!pkg) {
      return null
    }

    return (
      <>
        <div
          className={css({
            pos: 'absolute',
            rotate: '45deg',
            translate: '-50% -50%',
            top: '50%',
            left: '50%',
            display: 'flex',
            flexDir: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bg: 'rgba(255, 255, 255, .15)',
            cursor: 'pointer',
            transition: '.3s',
            lgDown: {
              rotate: '-0deg',
            },
            _before: {
              translate: '-50%',
              content: '""',
              pos: 'absolute',
              left: '50%',
              bottom: '0',
              h: '5%',
              bg: 'rgba(255,255,255,0.8)',
              w: '110%',
              transition: '.5s',
              zIndex: '-1',
            },
            _hover: {
              '& .line': {
                color: 'black',
                _before: {
                  bg: 'black',
                },
              },
              _before: {
                h: '100%',
              },
            },
            '@media (min-width: 768px)': {
              h: '360px',
              w: '360px',
              transform: 'scale(1.5)',
            },
          })}
        >
          <span
            className={cx(
              'line',
              css({
                color: 'white',
                letterSpacing: '2px',
                fontSize: '25px',
                textTransform: 'uppercase',
                fontWeight: '500',
                pos: 'relative',
                transition: '.3s',
                _before: {
                  translate: '-50%',
                  content: '""',
                  pos: 'absolute',
                  left: '50%',
                  bottom: '0',
                  h: '5%',
                  bg: 'white',
                  w: '110%',
                  transition: '.3s',
                  zIndex: '-1',
                },
              })
            )}
          >
            {pkg?.label}
          </span>
        </div>

        <Image
          relativePath={`packages/${pkg.id}/${imageName}`}
          className={css({
            objectFit: 'cover',
            pos: 'absolute',
            left: '0',
            top: '0',
            zIndex: '-1',
            width: '100%',
            height: '100%',
            transition: '.3s',
            rotate: '45deg',
            lgDown: {
              rotate: '-0deg',
            },
            '@media (min-width: 768px)': {
              transform: 'scale(1.5)',
            },
          })}
        />
      </>
    )
  }

  const getPlaceHolderImage = (name: string) => {
    return (
      <Image
        relativePath={`home/${name}`}
        className={css({
          objectFit: 'cover',
          rotate: '45deg',
          pos: 'absolute',
          translate: '-50% -50%',
          left: '50%',
          top: '50%',
          zIndex: '-1',
          minW: 'calc(100% + 60px)',
          transition: '.3s',
          lgDown: {
            rotate: '-0deg',
          },
        })}
      />
    )
  }

  return (
    <div
      className={css({
        pos: 'relative',
        width: '100%',
        '@media (min-width: 768px)': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          flexGrow: 0,
        },
      })}
      style={{
        background: "url('" + getPath('home/bg.png') + "')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <div
        className={css({
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          gridTemplateRows: 'repeat(4, 360px)',
          gap: '10px',
          gridAutoFlow: 'row',
          justifyItems: 'center',
          gridTemplateAreas:
            "'GROSSESSE . .' 'COUPLE BEBE CHILDS1' 'CHILDS2 NOEL NAISSANCE' '. CHILDS3 BAPTEME'",
          zIndex: 99,
          transform: 'rotate(-45deg)',
          transition: '.3s',
          '@media (min-width: 768px)': {
            display: 'grid',
            marginTop: '-140px',
            '& .CHILDS1, & .CHILDS2, & .CHILDS3': {
              width: '360px',
            },
          },
          '@media (max-width: 768px)': {
            marginTop: '10rem',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            transform: 'rotate(0deg)',
            gridTemplateAreas:
              "'GROSSESSE COUPLE' 'BEBE NOEL' 'NAISSANCE BAPTEME'",
            gridTemplateRows: 'repeat(3, 360px)',
            '& .CHILDS1, & .CHILDS2, & .CHILDS3': {
              display: 'none !important',
            },
            '& > div': {
              // width: '360px',
              height: '360px',
            },
          },
          '& > div': {
            pos: 'relative',
            overflow: 'hidden',
            // width: '360px',
            height: '360px',
            '& > div': {
              pos: 'relative',
              overflow: 'hidden',
            },
          },
        })}
      >
        <div
          className={`GROSSESSE ${css({ '@media (max-width: 768px)': { width: '100%' } })}`}
          onClick={() => handleClickNav('shooting-photo-grossesse')}
        >
          {getPackage('pregnancy')}
        </div>
        <div
          className={`COUPLE ${css({ '@media (max-width: 768px)': { width: '100%' } })}`}
          onClick={() => handleClickNav('photographe-couple')}
        >
          {getPackage('couple')}
        </div>
        <div
          className={`BEBE ${css({ '@media (max-width: 768px)': { width: '100%' } })}`}
          onClick={() => handleClickNav('photographe-bebe')}
        >
          {getPackage('babys')}
        </div>
        <div
          className={`NOEL ${css({ '@media (max-width: 768px)': { width: '100%' } })}`}
          onClick={() => handleClickNav('photographe-de-noel')}
        >
          {getPackage('noel')}
        </div>
        <div
          className={`NAISSANCE ${css({ '@media (max-width: 768px)': { width: '100%' } })}`}
          onClick={() => handleClickNav('photographe-pour-naissance')}
        >
          {getPackage('birth')}
        </div>
        <div
          className={`BAPTEME ${css({ '@media (max-width: 768px)': { width: '100%' } })}`}
          onClick={() => handleClickNav('photographe-professionnelle')}
        >
          {getPackage('pro')}
        </div>

        <div className="CHILDS1">
          <div className="PHOTO1">
            {getPlaceHolderImage('placeholder1.png')}
          </div>
        </div>
        <div className="CHILDS2">
          <div className="PHOTO1">
            {getPlaceHolderImage('placeholder2.png')}
          </div>
          <div className="PHOTO2">
            {getPlaceHolderImage('placeholder3.png')}
          </div>
          <div className="PHOTO3">
            {getPlaceHolderImage('placeholder4.png')}
          </div>
        </div>
        <div className="CHILDS3">
          <div className="PHOTO1">
            {getPlaceHolderImage('placeholder5.png')}
          </div>
          <div className="PHOTO2">
            {getPlaceHolderImage('placeholder6.png')}
          </div>
          <div className="PHOTO3">
            {getPlaceHolderImage('placeholder7.png')}
          </div>
        </div>
      </div>
    </div>
  )
}
